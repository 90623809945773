<!--
 * @Autor: Hongting Yuan
 * @Date: 2022-08-16 15:24:27
 * @LastEditors: Hongting Yuan
 * @LastEditTime: 2022-08-16 18:44:45
 * @Description: file content
 * @FilePath: \video_test\src\views\Video.vue
-->
<style>
.buttonBox{
  display: flex; padding: 20px;
}
.el-button{
  text-size-adjust: 100%;
  --el-color-white: #ffffff;
  --el-color-black: #000000;
  --el-color-primary-rgb: 64, 158, 255;
  --el-color-success-rgb: 103, 194, 58;
  --el-color-warning-rgb: 230, 162, 60;
  --el-color-danger-rgb: 245, 108, 108;
  --el-color-error-rgb: 245, 108, 108;
  --el-color-info-rgb: 144, 147, 153;
  --el-font-size-extra-large: 20px;
  --el-font-size-large: 18px;
  --el-font-size-medium: 16px;
  --el-font-size-base: 14px;
  --el-font-size-small: 13px;
  --el-font-size-extra-small: 12px;
  --el-font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  --el-font-weight-primary: 500;
  --el-font-line-height-primary: 24px;
  --el-index-normal: 1;
  --el-index-top: 1000;
  --el-index-popper: 2000;
  --el-border-radius-base: 4px;
  --el-border-radius-small: 2px;
  --el-border-radius-round: 20px;
  --el-border-radius-circle: 100%;
  --el-transition-duration: .3s;
  --el-transition-duration-fast: .2s;
  --el-transition-function-ease-in-out-bezier: cubic-bezier(.645, .045, .355, 1);
  --el-transition-function-fast-bezier: cubic-bezier(.23, 1, .32, 1);
  --el-transition-all: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  --el-transition-fade: opacity var(--el-transition-duration) var(--el-transition-function-fast-bezier);
  --el-transition-md-fade: transform var(--el-transition-duration) var(--el-transition-function-fast-bezier), opacity var(--el-transition-duration) var(--el-transition-function-fast-bezier);
  --el-transition-fade-linear: opacity var(--el-transition-duration-fast) linear;
  --el-transition-border: border-color var(--el-transition-duration-fast) var(--el-transition-function-ease-in-out-bezier);
  --el-transition-box-shadow: box-shadow var(--el-transition-duration-fast) var(--el-transition-function-ease-in-out-bezier);
  --el-transition-color: color var(--el-transition-duration-fast) var(--el-transition-function-ease-in-out-bezier);
  --el-component-size-large: 40px;
  --el-component-size: 32px;
  --el-component-size-small: 24px;
  color-scheme: light;
  --el-color-primary: #409eff;
  --el-color-primary-light-3: rgb(121.3, 187.1, 255);
  --el-color-primary-light-5: rgb(159.5, 206.5, 255);
  --el-color-primary-light-7: rgb(197.7, 225.9, 255);
  --el-color-primary-light-8: rgb(216.8, 235.6, 255);
  --el-color-primary-light-9: rgb(235.9, 245.3, 255);
  --el-color-primary-dark-2: rgb(51.2, 126.4, 204);
  --el-color-success: #67c23a;
  --el-color-success-light-3: rgb(148.6, 212.3, 117.1);
  --el-color-success-light-5: rgb(179, 224.5, 156.5);
  --el-color-success-light-7: rgb(209.4, 236.7, 195.9);
  --el-color-success-light-8: rgb(224.6, 242.8, 215.6);
  --el-color-success-light-9: rgb(239.8, 248.9, 235.3);
  --el-color-success-dark-2: rgb(82.4, 155.2, 46.4);
  --el-color-warning: #e6a23c;
  --el-color-warning-light-3: rgb(237.5, 189.9, 118.5);
  --el-color-warning-light-5: rgb(242.5, 208.5, 157.5);
  --el-color-warning-light-7: rgb(247.5, 227.1, 196.5);
  --el-color-warning-light-8: rgb(250, 236.4, 216);
  --el-color-warning-light-9: rgb(252.5, 245.7, 235.5);
  --el-color-warning-dark-2: rgb(184, 129.6, 48);
  --el-color-danger: #f56c6c;
  --el-color-danger-light-3: rgb(248, 152.1, 152.1);
  --el-color-danger-light-5: rgb(250, 181.5, 181.5);
  --el-color-danger-light-7: rgb(252, 210.9, 210.9);
  --el-color-danger-light-8: rgb(253, 225.6, 225.6);
  --el-color-danger-light-9: rgb(254, 240.3, 240.3);
  --el-color-danger-dark-2: rgb(196, 86.4, 86.4);
  --el-color-error: #f56c6c;
  --el-color-error-light-3: rgb(248, 152.1, 152.1);
  --el-color-error-light-5: rgb(250, 181.5, 181.5);
  --el-color-error-light-7: rgb(252, 210.9, 210.9);
  --el-color-error-light-8: rgb(253, 225.6, 225.6);
  --el-color-error-light-9: rgb(254, 240.3, 240.3);
  --el-color-error-dark-2: rgb(196, 86.4, 86.4);
  --el-color-info: #909399;
  --el-color-info-light-3: rgb(177.3, 179.4, 183.6);
  --el-color-info-light-5: rgb(199.5, 201, 204);
  --el-color-info-light-7: rgb(221.7, 222.6, 224.4);
  --el-color-info-light-8: rgb(232.8, 233.4, 234.6);
  --el-color-info-light-9: rgb(243.9, 244.2, 244.8);
  --el-color-info-dark-2: rgb(115.2, 117.6, 122.4);
  --el-bg-color: #ffffff;
  --el-bg-color-page: #f2f3f5;
  --el-bg-color-overlay: #ffffff;
  --el-text-color-primary: #303133;
  --el-text-color-regular: #606266;
  --el-text-color-secondary: #909399;
  --el-text-color-placeholder: #a8abb2;
  --el-text-color-disabled: #c0c4cc;
  --el-border-color: #dcdfe6;
  --el-border-color-light: #e4e7ed;
  --el-border-color-lighter: #ebeef5;
  --el-border-color-extra-light: #f2f6fc;
  --el-border-color-dark: #d4d7de;
  --el-border-color-darker: #cdd0d6;
  --el-fill-color: #f0f2f5;
  --el-fill-color-light: #f5f7fa;
  --el-fill-color-lighter: #fafafa;
  --el-fill-color-extra-light: #fafcff;
  --el-fill-color-dark: #ebedf0;
  --el-fill-color-darker: #e6e8eb;
  --el-fill-color-blank: #ffffff;
  --el-box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, .04), 0px 8px 20px rgba(0, 0, 0, .08);
  --el-box-shadow-light: 0px 0px 12px rgba(0, 0, 0, .12);
  --el-box-shadow-lighter: 0px 0px 6px rgba(0, 0, 0, .12);
  --el-box-shadow-dark: 0px 16px 48px 16px rgba(0, 0, 0, .08), 0px 12px 32px rgba(0, 0, 0, .12), 0px 8px 16px -8px rgba(0, 0, 0, .16);
  --el-disabled-bg-color: var(--el-fill-color-light);
  --el-disabled-text-color: var(--el-text-color-placeholder);
  --el-disabled-border-color: var(--el-border-color-light);
  --el-overlay-color: rgba(0, 0, 0, .8);
  --el-overlay-color-light: rgba(0, 0, 0, .7);
  --el-overlay-color-lighter: rgba(0, 0, 0, .5);
  --el-mask-color: rgba(255, 255, 255, .9);
  --el-mask-color-extra-light: rgba(255, 255, 255, .3);
  --el-border-width: 1px;
  --el-border-style: solid;
  --el-border-color-hover: var(--el-text-color-disabled);
  --el-border: var(--el-border-width) var(--el-border-style) var(--el-border-color);
  --el-svg-monochrome-grey: var(--el-border-color);
  --el-popup-modal-bg-color: var(--el-color-black);
  --el-popup-modal-opacity: .5;
  --el-loading-spinner-size: 42px;
  --el-loading-fullscreen-spinner-size: 50px;
  --el-menu-active-color: var(--el-color-primary);
  --el-menu-text-color: var(--el-text-color-primary);
  --el-menu-hover-text-color: var(--el-color-primary);
  --el-menu-bg-color: var(--el-fill-color-blank);
  --el-menu-hover-bg-color: var(--el-color-primary-light-9);
  --el-menu-item-height: 56px;
  --el-menu-sub-item-height: calc(var(--el-menu-item-height) - 6px);
  --el-menu-horizontal-height: 60px;
  --el-menu-horizontal-sub-item-height: 36px;
  --el-menu-item-font-size: var(--el-font-size-base);
  --el-menu-item-hover-fill: var(--el-color-primary-light-9);
  --el-menu-border-color: var(--el-border-color);
  --el-menu-base-level-padding: 20px;
  --el-menu-level-padding: 20px;
  --el-menu-icon-width: 24px;
  --text-color: var(--el-text-color-primary);
  --text-color-light: var(--el-text-color-regular);
  --text-color-lighter: var(--el-text-color-secondary);
  --brand-color: var(--el-color-primary);
  --brand-color-light: var(--el-color-primary-light-1);
  --bg-brand-color: var(--el-color-primary-light-9);
  --bg-color: var(--el-bg-color);
  --bg-color-rgb: 255, 255, 255;
  --bg-color-soft: #fafafa;
  --bg-color-mute: #f2f2f2;
  --border-color: var(--el-border-color);
  --border-color-light: var(--el-border-color-lighter);
  --font-family: var(--el-font-family);
  --font-family-mono: "JetBrains Mono", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  --success-color: var(--el-color-success);
  --warning-color: var(--el-color-warning);
  --danger-color: var(--el-color-danger);
  --purple-color: #6222c2;
  --purple-color-light: #9065db;
  --header-height: 55px;
  --nav-height: 55px;
  --vp-screen-max-width: 1362px;
  --vp-sidebar-width-mobile: 320px;
  --vp-sidebar-width-small: 266px;
  --sidebar-width-sm: 16rem;
  --sidebar-width-xs: 20rem;
  --content-min-width: 16rem;
  --content-max-width: 48rem;
  --nav-z-index: 12;
  --sub-nav-z-index: 11;
  --sidebar-z-index: 11;
  --sidebar-z-index-mobile: 31;
  --overlay-z-index: 30;
  --code-line-height: 1.4;
  --code-font-size: var(--el-font-size-base);
  --code-bg-color: var(--el-fill-color-light);
  --code-text-color: var(--text-color);
  --code-font-family: var(--font-family-mono);
  --code-tooltip-bg-color: var(--code-bg-color);
  --code-tooltip-color: #0c61c9;
  --block-tip-bg-color: rgba(var(--el-color-primary-rgb), .1);
  --block-warning-bg-color: rgba(var(--el-color-danger-rgb), .1);
  --link-active-bg-color: rgba(var(--el-color-primary-rgb), .1);
  --vp-c-white: #ffffff;
  --vp-c-black: #000000;
  --vp-c-neutral: var(--vp-c-black);
  --vp-c-neutral-inverse: var(--vp-c-white);
  --vp-c-gray-1: #dddde3;
  --vp-c-gray-2: #e4e4e9;
  --vp-c-gray-3: #ebebef;
  --vp-c-gray-soft: rgba(142, 150, 170, .14);
  --vp-c-indigo-1: #3451b2;
  --vp-c-indigo-2: #3a5ccc;
  --vp-c-indigo-3: #5672cd;
  --vp-c-indigo-soft: rgba(100, 108, 255, .14);
  --vp-c-purple-1: #6f42c1;
  --vp-c-purple-2: #7e4cc9;
  --vp-c-purple-3: #8e5cd9;
  --vp-c-purple-soft: rgba(159, 122, 234, .14);
  --vp-c-green-1: #18794e;
  --vp-c-green-2: #299764;
  --vp-c-green-3: #30a46c;
  --vp-c-green-soft: rgba(16, 185, 129, .14);
  --vp-c-yellow-1: #915930;
  --vp-c-yellow-2: #946300;
  --vp-c-yellow-3: #9f6a00;
  --vp-c-yellow-soft: rgba(234, 179, 8, .14);
  --vp-c-red-1: #b8272c;
  --vp-c-red-2: #d5393e;
  --vp-c-red-3: #e0575b;
  --vp-c-red-soft: rgba(244, 63, 94, .14);
  --vp-c-sponsor: #db2777;
  --vp-c-bg: #ffffff;
  --vp-c-bg-alt: #f6f6f7;
  --vp-c-bg-elv: #ffffff;
  --vp-c-bg-soft: #f6f6f7;
  --vp-c-border: #c2c2c4;
  --vp-c-divider: #e2e2e3;
  --vp-c-gutter: #e2e2e3;
  --vp-c-text-1: rgba(60, 60, 67);
  --vp-c-text-2: rgba(60, 60, 67, .78);
  --vp-c-text-3: rgba(60, 60, 67, .56);
  --vp-c-default-1: var(--vp-c-gray-1);
  --vp-c-default-2: var(--vp-c-gray-2);
  --vp-c-default-3: var(--vp-c-gray-3);
  --vp-c-default-soft: var(--vp-c-gray-soft);
  --vp-c-brand-1: var(--vp-c-indigo-1);
  --vp-c-brand-2: var(--vp-c-indigo-2);
  --vp-c-brand-3: var(--vp-c-indigo-3);
  --vp-c-brand-soft: var(--vp-c-indigo-soft);
  --vp-c-brand: var(--vp-c-brand-1);
  --vp-c-tip-1: var(--vp-c-brand-1);
  --vp-c-tip-2: var(--vp-c-brand-2);
  --vp-c-tip-3: var(--vp-c-brand-3);
  --vp-c-tip-soft: var(--vp-c-brand-soft);
  --vp-c-note-1: var(--vp-c-brand-1);
  --vp-c-note-2: var(--vp-c-brand-2);
  --vp-c-note-3: var(--vp-c-brand-3);
  --vp-c-note-soft: var(--vp-c-brand-soft);
  --vp-c-success-1: var(--vp-c-green-1);
  --vp-c-success-2: var(--vp-c-green-2);
  --vp-c-success-3: var(--vp-c-green-3);
  --vp-c-success-soft: var(--vp-c-green-soft);
  --vp-c-important-1: var(--vp-c-purple-1);
  --vp-c-important-2: var(--vp-c-purple-2);
  --vp-c-important-3: var(--vp-c-purple-3);
  --vp-c-important-soft: var(--vp-c-purple-soft);
  --vp-c-warning-1: var(--vp-c-yellow-1);
  --vp-c-warning-2: var(--vp-c-yellow-2);
  --vp-c-warning-3: var(--vp-c-yellow-3);
  --vp-c-warning-soft: var(--vp-c-yellow-soft);
  --vp-c-danger-1: var(--vp-c-red-1);
  --vp-c-danger-2: var(--vp-c-red-2);
  --vp-c-danger-3: var(--vp-c-red-3);
  --vp-c-danger-soft: var(--vp-c-red-soft);
  --vp-c-caution-1: var(--vp-c-red-1);
  --vp-c-caution-2: var(--vp-c-red-2);
  --vp-c-caution-3: var(--vp-c-red-3);
  --vp-c-caution-soft: var(--vp-c-red-soft);
  --vp-font-family-base: "Inter", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --vp-font-family-mono: ui-monospace, "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", monospace;
  --vp-shadow-1: 0 1px 2px rgba(0, 0, 0, .04), 0 1px 2px rgba(0, 0, 0, .06);
  --vp-shadow-2: 0 3px 12px rgba(0, 0, 0, .07), 0 1px 4px rgba(0, 0, 0, .07);
  --vp-shadow-3: 0 12px 32px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .08);
  --vp-shadow-4: 0 14px 44px rgba(0, 0, 0, .12), 0 3px 9px rgba(0, 0, 0, .12);
  --vp-shadow-5: 0 18px 56px rgba(0, 0, 0, .16), 0 4px 12px rgba(0, 0, 0, .16);
  --vp-z-index-footer: 10;
  --vp-z-index-local-nav: 20;
  --vp-z-index-nav: 30;
  --vp-z-index-layout-top: 40;
  --vp-z-index-backdrop: 50;
  --vp-z-index-sidebar: 60;
  --vp-layout-max-width: 1440px;
  --vp-header-anchor-symbol: "#";
  --vp-code-font-size: .875em;
  --vp-code-color: var(--vp-c-brand-1);
  --vp-code-link-color: var(--vp-c-brand-1);
  --vp-code-link-hover-color: var(--vp-c-brand-2);
  --vp-code-bg: var(--vp-c-default-soft);
  --vp-code-block-color: var(--vp-c-text-2);
  --vp-code-block-divider-color: var(--vp-c-gutter);
  --vp-code-lang-color: var(--vp-c-text-3);
  --vp-code-line-highlight-color: var(--vp-c-default-soft);
  --vp-code-line-number-color: var(--vp-c-text-3);
  --vp-code-line-diff-add-color: var(--vp-c-success-soft);
  --vp-code-line-diff-add-symbol-color: var(--vp-c-success-1);
  --vp-code-line-diff-remove-color: var(--vp-c-danger-soft);
  --vp-code-line-diff-remove-symbol-color: var(--vp-c-danger-1);
  --vp-code-line-warning-color: var(--vp-c-warning-soft);
  --vp-code-line-error-color: var(--vp-c-danger-soft);
  --vp-code-copy-code-border-color: var(--vp-c-divider);
  --vp-code-copy-code-bg: var(--vp-c-bg-soft);
  --vp-code-copy-code-hover-border-color: var(--vp-c-divider);
  --vp-code-copy-code-hover-bg: var(--vp-c-bg);
  --vp-code-copy-code-active-text: var(--vp-c-text-2);
  --vp-code-copy-copied-text-content: "Copied";
  --vp-code-tab-divider: var(--vp-code-block-divider-color);
  --vp-code-tab-text-color: var(--vp-c-text-2);
  --vp-code-tab-bg: var(--vp-code-block-bg);
  --vp-code-tab-hover-text-color: var(--vp-c-text-1);
  --vp-code-tab-active-text-color: var(--vp-c-text-1);
  --vp-code-tab-active-bar-color: var(--vp-c-brand-1);
  --vp-button-brand-border: transparent;
  --vp-button-brand-text: var(--vp-c-white);
  --vp-button-brand-bg: var(--vp-c-brand-3);
  --vp-button-brand-hover-border: transparent;
  --vp-button-brand-hover-text: var(--vp-c-white);
  --vp-button-brand-hover-bg: var(--vp-c-brand-2);
  --vp-button-brand-active-border: transparent;
  --vp-button-brand-active-text: var(--vp-c-white);
  --vp-button-brand-active-bg: var(--vp-c-brand-1);
  --vp-button-alt-border: transparent;
  --vp-button-alt-text: var(--vp-c-text-1);
  --vp-button-alt-bg: var(--vp-c-default-3);
  --vp-button-alt-hover-border: transparent;
  --vp-button-alt-hover-text: var(--vp-c-text-1);
  --vp-button-alt-hover-bg: var(--vp-c-default-2);
  --vp-button-alt-active-border: transparent;
  --vp-button-alt-active-text: var(--vp-c-text-1);
  --vp-button-alt-active-bg: var(--vp-c-default-1);
  --vp-button-sponsor-border: var(--vp-c-text-2);
  --vp-button-sponsor-text: var(--vp-c-text-2);
  --vp-button-sponsor-bg: transparent;
  --vp-button-sponsor-hover-border: var(--vp-c-sponsor);
  --vp-button-sponsor-hover-text: var(--vp-c-sponsor);
  --vp-button-sponsor-hover-bg: transparent;
  --vp-button-sponsor-active-border: var(--vp-c-sponsor);
  --vp-button-sponsor-active-text: var(--vp-c-sponsor);
  --vp-button-sponsor-active-bg: transparent;
  --vp-custom-block-font-size: 14px;
  --vp-custom-block-code-font-size: 13px;
  --vp-custom-block-info-border: transparent;
  --vp-custom-block-info-text: var(--vp-c-text-1);
  --vp-custom-block-info-bg: var(--vp-c-default-soft);
  --vp-custom-block-info-code-bg: var(--vp-c-default-soft);
  --vp-custom-block-note-border: transparent;
  --vp-custom-block-note-text: var(--vp-c-text-1);
  --vp-custom-block-note-bg: var(--vp-c-default-soft);
  --vp-custom-block-note-code-bg: var(--vp-c-default-soft);
  --vp-custom-block-tip-border: transparent;
  --vp-custom-block-tip-text: var(--vp-c-text-1);
  --vp-custom-block-tip-bg: var(--vp-c-tip-soft);
  --vp-custom-block-tip-code-bg: var(--vp-c-tip-soft);
  --vp-custom-block-important-border: transparent;
  --vp-custom-block-important-text: var(--vp-c-text-1);
  --vp-custom-block-important-bg: var(--vp-c-important-soft);
  --vp-custom-block-important-code-bg: var(--vp-c-important-soft);
  --vp-custom-block-warning-border: transparent;
  --vp-custom-block-warning-text: var(--vp-c-text-1);
  --vp-custom-block-warning-bg: var(--vp-c-warning-soft);
  --vp-custom-block-warning-code-bg: var(--vp-c-warning-soft);
  --vp-custom-block-danger-border: transparent;
  --vp-custom-block-danger-text: var(--vp-c-text-1);
  --vp-custom-block-danger-bg: var(--vp-c-danger-soft);
  --vp-custom-block-danger-code-bg: var(--vp-c-danger-soft);
  --vp-custom-block-caution-border: transparent;
  --vp-custom-block-caution-text: var(--vp-c-text-1);
  --vp-custom-block-caution-bg: var(--vp-c-caution-soft);
  --vp-custom-block-caution-code-bg: var(--vp-c-caution-soft);
  --vp-custom-block-details-border: var(--vp-custom-block-info-border);
  --vp-custom-block-details-text: var(--vp-custom-block-info-text);
  --vp-custom-block-details-bg: var(--vp-custom-block-info-bg);
  --vp-custom-block-details-code-bg: var(--vp-custom-block-info-code-bg);
  --vp-input-border-color: var(--vp-c-border);
  --vp-input-bg-color: var(--vp-c-bg-alt);
  --vp-input-switch-bg-color: var(--vp-c-default-soft);
  --vp-nav-height: 64px;
  --vp-nav-bg-color: var(--vp-c-bg);
  --vp-nav-screen-bg-color: var(--vp-c-bg);
  --vp-nav-logo-height: 24px;
  --vp-local-nav-bg-color: var(--vp-c-bg);
  --vp-sidebar-width: 272px;
  --vp-sidebar-bg-color: var(--vp-c-bg-alt);
  --vp-backdrop-bg-color: rgba(0, 0, 0, .6);
  --vp-home-hero-name-color: var(--vp-c-brand-1);
  --vp-home-hero-name-background: transparent;
  --vp-home-hero-image-background-image: none;
  --vp-home-hero-image-filter: none;
  --vp-badge-info-border: transparent;
  --vp-badge-info-text: var(--vp-c-text-2);
  --vp-badge-info-bg: var(--vp-c-default-soft);
  --vp-badge-tip-border: transparent;
  --vp-badge-tip-text: var(--vp-c-tip-1);
  --vp-badge-tip-bg: var(--vp-c-tip-soft);
  --vp-badge-warning-border: transparent;
  --vp-badge-warning-text: var(--vp-c-warning-1);
  --vp-badge-warning-bg: var(--vp-c-warning-soft);
  --vp-badge-danger-border: transparent;
  --vp-badge-danger-text: var(--vp-c-danger-1);
  --vp-badge-danger-bg: var(--vp-c-danger-soft);
  --vp-carbon-ads-text-color: var(--vp-c-text-1);
  --vp-carbon-ads-poweredby-color: var(--vp-c-text-2);
  --vp-carbon-ads-bg-color: var(--vp-c-bg-soft);
  --vp-carbon-ads-hover-text-color: var(--vp-c-brand-1);
  --vp-carbon-ads-hover-poweredby-color: var(--vp-c-text-1);
  --vp-local-search-bg: var(--vp-c-bg);
  --vp-local-search-result-bg: var(--vp-c-bg);
  --vp-local-search-result-border: var(--vp-c-divider);
  --vp-local-search-result-selected-bg: var(--vp-c-bg);
  --vp-local-search-result-selected-border: var(--vp-c-brand-1);
  --vp-local-search-highlight-bg: var(--vp-c-brand-1);
  --vp-local-search-highlight-text: var(--vp-c-neutral-inverse);
  --vp-icon-copy: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='rgba(128,128,128,1)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24'%3E%3Crect width='8' height='4' x='8' y='2' rx='1' ry='1'/%3E%3Cpath d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2'/%3E%3C/svg%3E");
  --vp-icon-copied: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='rgba(128,128,128,1)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24'%3E%3Crect width='8' height='4' x='8' y='2' rx='1' ry='1'/%3E%3Cpath d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2'/%3E%3Cpath d='m9 14 2 2 4-4'/%3E%3C/svg%3E");
  --vp-code-block-bg: var(--el-fill-color-light);
  --vp-code-line-height: 1.6;
  --docsearch-primary-color: #5468ff;
  --docsearch-text-color: #1c1e21;
  --docsearch-spacing: 12px;
  --docsearch-icon-stroke-width: 1.4;
  --docsearch-highlight-color: var(--docsearch-primary-color);
  --docsearch-muted-color: #969faf;
  --docsearch-container-background: rgba(101,108,133,.8);
  --docsearch-logo-color: #5468ff;
  --docsearch-modal-width: 560px;
  --docsearch-modal-height: 600px;
  --docsearch-modal-background: #f5f6f7;
  --docsearch-modal-shadow: inset 1px 1px 0 0 hsla(0,0%,100%,.5),0 3px 8px 0 #555a64;
  --docsearch-searchbox-height: 56px;
  --docsearch-searchbox-background: #ebedf0;
  --docsearch-searchbox-focus-background: #fff;
  --docsearch-searchbox-shadow: inset 0 0 0 2px var(--docsearch-primary-color);
  --docsearch-hit-height: 56px;
  --docsearch-hit-color: #444950;
  --docsearch-hit-active-color: #fff;
  --docsearch-hit-background: #fff;
  --docsearch-hit-shadow: 0 1px 3px 0 #d4d9e1;
  --docsearch-key-gradient: linear-gradient(-225deg,#d5dbe4,#f8f8f8);
  --docsearch-key-shadow: inset 0 -2px 0 0 #cdcde6,inset 0 0 1px 1px #fff,0 1px 2px 1px rgba(30,35,90,.4);
  --docsearch-footer-height: 44px;
  --docsearch-footer-background: #fff;
  --docsearch-footer-shadow: 0 -1px 0 0 #e0e3e8,0 -3px 6px 0 rgba(69,98,155,.12);
  --ep-c-bg-row: #f9fafc;
  --ep-c-bg-purple: #d3dce6;
  --ep-c-bg-purple-dark: #99a9bf;
  --ep-c-bg-purple-light: #e5e9f2;
  --categories-c-bg: #F9FAFC;
  --categories-c-page: #E5E9F2;
  --categories-c-overlay: white;
  --categories-c-text: #99A9BF;
  --categories-c-icon: #E5E9F2;
  --categories-c-line: #E5E9F2;
  -webkit-tap-highlight-color: transparent;
  direction: ltr;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  --vp-content-width: 800px;
  scrollbar-color: var(--el-scrollbar-bg-color) var(--el-fill-color-light);
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-shadow: 0 0 rgb(0 0 0 / 0);
  --un-shadow-inset: ;
  --un-shadow: 0 0 rgb(0 0 0 / 0);
  --un-ring-inset: ;
  --un-ring-offset-width: 0px;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0px;
  --un-ring-color: rgb(147 197 253 / .5);
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
  margin: 0;
  overflow: visible;
  text-transform: none;
  font-family: inherit;
  touch-action: manipulation;
  --el-button-font-weight: var(--el-font-weight-primary);
  --el-button-divide-border-color: rgba(255, 255, 255, .5);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 32px;
  white-space: nowrap;
  cursor: pointer;
  color: var(--el-button-text-color);
  text-align: center;
  box-sizing: border-box;
  outline: none;
  transition: .1s;
  font-weight: var(--el-button-font-weight);
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  background-color: var(--el-button-bg-color);
  border: var(--el-border);
  border-color: var(--el-button-border-color);
  padding: 8px 15px;
  font-size: var(--el-font-size-base);
  border-radius: var(--el-border-radius-base);
  --el-button-outline-color: var(--el-color-primary-light-5);
  --el-button-active-color: var(--el-color-primary-dark-2);
  --el-button-hover-link-text-color: var(--el-color-primary-light-5);
  --el-button-active-bg-color: var(--el-color-primary-dark-2);
  --el-button-active-border-color: var(--el-color-primary-dark-2);
  --el-button-disabled-text-color: var(--el-color-white);
  --el-button-disabled-bg-color: var(--el-color-primary-light-5);
  --el-button-disabled-border-color: var(--el-color-primary-light-5);
  margin-left: 12px;
  --el-button-text-color: var(--el-color-primary);
  --el-button-bg-color: var(--el-color-primary-light-9);
  --el-button-border-color: var(--el-color-primary-light-5);
  --el-button-hover-text-color: var(--el-color-white);
  --el-button-hover-bg-color: var(--el-color-primary);
  --el-button-hover-border-color: var(--el-color-primary);
  --el-button-active-text-color: var(--el-color-white);
}
.cameraView{
  width: 100%;
  height: 100%;
}
.cameraView video{
  margin: 5%;
  width: 90%;
  background-color: rgba(84, 92, 100, 0.6);
}
.playView{
  width: 100%;
  height: 100%;
}
.playView video{
  margin: 5%;
  width: 90%;
  background-color: rgba(84, 92, 100, 0.6);
}
</style>
<template>
  <div>
    <div class="buttonBox">
      <button class="el-button" @click="callCamera()">开始录制</button>
      <button class="el-button" @click="record()">结束录制</button>
      <button class="el-button" @click="submit()">下载或上传</button>
    </div>
    <div class="cameraView">
      <video id="video" autoplay ref="videos"  muted></video>
    </div>
    <div class="playView">
      <video style="" id="videosreplay" src="" ref="videosreplay"></video>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import fixWebmDuration from 'webm-duration-fix'
  import FileSaver from 'file-saver'

  export default {
    name: "Test",
    data () {
      return {
        progress: 0,
        replayVideo: false,
        recordtype: "BEGIN",
        showReplay: true,
        timer: 0,
        recordtime: 0,
        second: 0,
        minute: 0,
        hour: 0,
        playtime: 0,
        playtimer: 0,
        yy_score: 0,
        cnt_sum: 0,
        ansMaxTime: 0,
        ansBeginTime: 0,
        ansMaxBeginTime: 0,

      }
    },
    methods: {
      // 调用摄像头
      callCamera () {
        let _this = this;
        // 后摄
        MediaUtils.getUserMedia({ facingMode: { exact: "environment" } }, true, function (err, stream) {
          // 前摄
        // MediaUtils.getUserMedia(true, true, function (err, stream) {
          if (err) {
            throw err;
          } else {
            // 通过 MediaRecorder 记录获取到的媒体流
            const mimeType = 'video/webm;codecs=vp8,opus';
            mediaRecorder = new MediaRecorder(stream, {
              // mimeType: "video/webm;codecs=vp9",
              mimeType: mimeType,
            });
            mediaStream = stream;
            var chunks = []
            var video = _this.$refs.videos;
            video["srcObject"] = stream;
            video.play();// 播放实时画面
            mediaRecorder.ondataavailable = function (e) {
              mediaRecorder.blobs.push(e.data);
              chunks.push(e.data);
              console.log("chunks", e.data)
              console.log("chunks size", e.data.size)
            };
            mediaRecorder.blobs = [];

            mediaRecorder.onstop = async () => {
              recorderFile = await fixWebmDuration(new Blob(chunks, { type: mimeType }));
              console.log("recorderFile", recorderFile);
              console.log("recorderFile size", recorderFile.size);
              var url = URL.createObjectURL(recorderFile)
              console.log("url准备填入播放器", url)
              var videosreplay = _this.$refs.videosreplay;
              videosreplay.setAttribute("src", url);
              console.log('url', url)
              chunks = [];
              if (null != stopRecordCallback) {
                stopRecordCallback();
              }
            };
            _this.record()
          }
        });
      },
      record () {
        if (this.recordtype == "ING") {
          this.stopRecord(() => {
            console.log("结束录制");
            this.toggleReplayVideo()
          });
        }
        else if (this.recordtype == "BEGIN") {
          console.log("开始录制");
          this.startAudio();
          mediaRecorder.start();
          startTime = Date.now();
          this.recordtype = "ING";
        }
      },

      // 对录像时长进行记录
      startAudio () {
        this.timer = setInterval(() => {
          this.recordtime += 1000;
          if (this.recordtime == 1000000) {
            this.stopRecord();
          }
          this.second++;
          if (this.second >= 60) {
            this.second = 0;
            this.minute = this.minute + 1;
          }

          if (this.minute >= 60) {
            this.minute = 0;
            this.hour = this.hour + 1;
          }
          console.log(this.recordtime)
        }, 1000);
      },

      // 停止录像时终止录制器，关闭媒体流并清除时长记录定时器
      stopRecord (callback) {
        this.recordtype = "END";
        this.showReplay = true;
        stopRecordCallback = callback;
        clearInterval(this.timer);
        // 终止录制器
        mediaRecorder.stop();
        // 关闭媒体流
        MediaUtils.closeStream(mediaStream);
        var videosreplay = this.$refs.videosreplay;
        videosreplay.onended = () => {
          this.playtime = 0;
          this.replayVideo = false;
          clearInterval(this.playtimer);
        };
        videosreplay.onclick = () => {
          this.showReplay = !this.showReplay;
        };
      },
      // 回放
      toggleReplayVideo () {
        console.log('播放中...')
        this.replayVideo = !this.replayVideo;
        this.showReplay = false;
        var videosreplay = this.$refs.videosreplay;
        if (this.replayVideo) {
          console.log('尝试播放')
          videosreplay.play().catch(err => {
            this.$message.error(err.message);
            console.log("播放视频崩溃", err.message);
          });
          console.log('尝试播放1')
          this.playtimer = setInterval(() => {
            this.playtime += 1000;
          }, 1000);
        } else {
          videosreplay.pause();
          clearInterval(this.playtimer);
        }
      },
      // 下载视频
      download () {
        FileSaver.saveAs(recorderFile, "录制的视频"+ ".mp4");
        // var url = URL.createObjectURL(recorderFile)
        // console.log("URLLLLLL", url)
        // const a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style.display = "none";
        // a.href = url;
        // if (this.fileName) {
        //   a.download = this.fileName + ".mp4";
        // } else {
        //   a.download = new Date() + ".mp4";
        // }
        // a.click();
        // window.URL.revokeObjectURL(url);
      },
      // 下载或上传
      submit () {
        let that = this;
        console.log(recorderFile)
        // 下载
        this.download()
        let file = new File(
          [recorderFile],
          "msr-" + new Date().toISOString().replace(/:|\./g, "-") + ".mp4",
          {
            type: "video/mp4",
          }
        );
        let config = {
          headers: { "Content-Type": "multipart/form-data" }
        }
        console.log('file', file)
        const formdata = new FormData()
        formdata.append("file", file);
        // 传给后端
        // axios.post('/video', formdata, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },) //请求头要为表单
        //   .then(response => {
        //     console.log('video', response.data);
        //     this.yy_score = parseInt(response.data.data + 0.5)
        //     that.progress = response.data.data * 1.0 / 23 * 100
        //   })
        //   .catch(function (error) {
        //     that.$message({
        //       message: error,
        //       type: 'error'
        //     });
        //     console.log(error);
        //   })
      },
    }
  }
  var MediaUtils = {
    /**
     * 获取用户媒体设备(处理兼容的问题)
     * @param videoEnable {boolean} - 是否启用摄像头
     * @param audioEnable {boolean} - 是否启用麦克风
     * @param callback {Function} - 处理回调
     */
    getUserMedia: function (videoEnable, audioEnable, callback) {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        window.getUserMedia;
      console.log("navigator.getUserMedia的值",navigator.getUserMedia)
      console.log("navigator.mediaDevices 的值",navigator.mediaDevices)
      console.log("navigator.webkitGetUserMedia 的值",navigator.webkitGetUserMedia)
      console.log("navigator.mozGetUserMedia 的值",navigator.mozGetUserMedia)
      console.log("navigator.msGetUserMedia 的值",navigator.msGetUserMedia)
      console.log("window.getUserMedia 的值",window.getUserMedia)
      var constraints = { video: videoEnable, audio: audioEnable };
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function (stream) {
            callback(false, stream);
          })
        ["catch"](function (err) {
          callback(err);
        });
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(
          constraints,
          function (stream) {
            callback(false, stream);
          },
          function (err) {
            callback(err);
          }
        );
      } else {
        callback(new Error("Not support userMedia"));
      }
    },

    /**
     * 关闭媒体流
     * @param stream {MediaStream} - 需要关闭的流
     */
    closeStream: function (stream) {
      if (typeof stream.stop === "function") {
        stream.stop();
      } else {
        let trackList = [stream.getAudioTracks(), stream.getVideoTracks()];

        for (let i = 0; i < trackList.length; i++) {
          let tracks = trackList[i];
          if (tracks && tracks.length > 0) {
            for (let j = 0; j < tracks.length; j++) {
              let track = tracks[j];
              if (typeof track.stop === "function") {
                track.stop();
              }
            }
          }
        }
      }
    },
  };
  var startTime, mediaRecorder, mediaStream, stopRecordCallback, recorderFile;
</script>